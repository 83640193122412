import { useEffect, useState } from "react"
import AllSongs from './songs.json'


const OneSong = (song) => {
    const { title, artist, genre, date_recorded, youtube_id, difficulty, tolerability } = song.song
    return (
        <div className="rounded-md w-11/12 md:w-[704px] transition-all bg-slate-500 m-2 flex flex-col p-4" >
            <h3 className="mb-2 text-center">{title} - {artist}</h3>
            <div className="flex justify-between flex-col sm:flex-row h-full items-center gap-2">
                <div>
                    <h4>Genre: {genre}</h4>
                    <h4>Date: {new Date(date_recorded).toLocaleDateString('en-US', { day: '2-digit', month: "long", year: "numeric" })}</h4>
                    <h4>Difficulty: {difficulty}</h4>
                    <h4>Tolerability: {tolerability}</h4>
                </div>
                <iframe src={`https://www.youtube.com/embed/${youtube_id}?si=kK4lrMARYXlzzrIM`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

        </div>
    )
}

export const BeatSaber = () => {
    const [songs, setSongs] = useState()
    useEffect(() => {
        // SetColors({ red: 255, green: 0, blue: 0 }, { red: 0, green: 0, blue: 255 })
        document.documentElement.style.setProperty("--from-color", `rgb(255, 0, 0)`);
        document.documentElement.style.setProperty("--to-color", `rgb(0, 0, 255)`);
        const sortedSongs = Object.values(AllSongs._default).sort((a, b) => {
            const dateA = new Date(a.date_recorded)
            const dateB = new Date(b.date_recorded)
            return dateA.getTime() > dateB.getTime() ? 1 : -1
        })
        sortedSongs.reverse()
        setSongs(sortedSongs)
        console.log(sortedSongs)
    }, [])

    return <div className="h-full flex flex-col items-center overflow-scroll">
        <h1 className="mt-20 mb-10 text-center">This is the beat saber page!</h1>
        {songs && songs.map((song, key) => {
            return <OneSong song={song} key={key} />
        })}
    </div>
}