import './tailwind.css'
import {
  createBrowserRouter,
  Link,
  RouterProvider,
} from "react-router-dom";
import { BeatSaber } from './BeatSaber/BeatSaber';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/beatsaber",
    element: <BeatSaber />,
  }
]);

function App() {
  return (
    <div style={{ transition: 'background-color 1s, color 1s' }} className={`h-screen bg-gradient-to-r from-[var(--from-color)] to-[var(--to-color)] relative`}>
      <div className='z-10 h-screen w-screen'>
        <RouterProvider router={router} />
      </div>
    </div>
  )
}

function Home() {
  return (
    <div className='flex justify-center items-center h-screen z-100'>
      <Link to={"/beatsaber"} className='z-10'>
        <div className='bg-gradient-to-r from-red-800 via-purple-800 to-blue-800 border-2 border-slate-900 cursor-pointer rounded-lg p-4'>
          <h1>Beat Saber!</h1>
        </div>
      </Link>
    </div>

  );
}
export default App;
